<template>
  <div class="start-sdc-app">
  </div>
</template>

<script>
export default {
  name: 'StartSDCApp',
  data() {
    return {}
  },
  mounted: function () {
    window.zE('webWidget', 'hide');
    window.location.href = "https://smartdogcollar.com/start"
  },
  created: function () {
  }
}
</script>